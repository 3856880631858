.insights {
  &--dpm {
    height: 80vh;

    &__category {
      max-width: 80%;
      font-size: 40px;
      font-family: $font-secondary;
      color: $white;
      left: -20px;
      bottom: 70px;
    }


    &__hero {
      background-color: $black !important; //Override default module styling

      .hero-content {
        @include media-breakpoint-up(lg) {
          padding-top: 180px !important; //Override default module styling
          padding-bottom: 180px !important; //Override default module styling
        }
      }
    }

    &__title,
    &__desc {
      color: $white !important; //Override default module settings

      @include media-breakpoint-up(sm) {
        max-width: 37vw !important; //Override default module settings
      }
    }

    &__title {
      @include media-breakpoint-down(lg) {
        font-size: 40px !important; //Override default module settings
      }

      @include media-breakpoint-down(sm) {
        font-size: 26px !important; //Override default module settings
      }
    }

    &__play {
      @include media-breakpoint-down(md) {
        color: $white !important; //Override other class settings
      }

      &__container {
        @include media-breakpoint-down(sm) {
          width: 0 !important; //Override existing values
        }
      }
    }

    &__carousel {
      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 350px;
        height: 180px;
        z-index: 100;
        position: absolute;
        right: 0;
        top: calc(50% - 80px);
        background-size: cover;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, black, transparent);
        opacity: .9;
      }

      &__content {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        color: $white;
      }

      &__text {
        position: absolute;
        color: $white;
        margin: 10px -120px;

        @include media-breakpoint-down(sm) {
          display: none;
        }

        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 1px;
          background-color: #fff;
          margin-top: 13px;
        }
      }

      &__title {
        max-width: 80%;
        font-size: 35px;
        font-family: $font-secondary;
        color: $white;
        position: absolute;
        left: -20px;
        top: 55px;
        line-height: 1.1;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &__timer {
        position: absolute;
        width: 90%;
        margin: 10px 20px;
        left: 0;
        bottom: 10px;
        background-color: $background-light;

        @include media-breakpoint-down(sm) {
          display: none;
        }

        .progress-bar {
          animation-timing-function: linear;
        }
      }

      &__button {
        color: $white !important; //Override other class colours
        transform: rotate(-90deg);

        @include media-breakpoint-down(sm) {
          display: none;
        }

        &:hover,
        &:active {
          color: $brand !important; //Override other class colours
          cursor: pointer;
        }

        &--up {
          margin-bottom: 200px;
          margin-top: -35px;
        }
      }
    }

    &__content {
      position: relative;
      z-index: 1;
    }
  }
}