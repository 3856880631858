/* Styling for the Central Text component */
.central-text-block {
  padding: 40px 20px;
  text-align: center;
  @include media-breakpoint-up(lg) {
    padding: 80px 20px;
  }
  .title {
    text-transform: uppercase;
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
  .content {
    p {
      font-size: 1.25rem;
      line-height: 1.75;
    }
  }
}