/* Styling for the Block Diagonal content section */
.block-diagonal-content {
  margin-top: 90px;
  .content {
    background: rgba($brand, .125);
    padding: 40px;
    @include media-breakpoint-up(md) {
      padding: 60px;
      width: 180%;
      min-width: 180%;
    }
    .title, p {
      @include media-breakpoint-up(md) {
        max-width: 50%;
      }
    }
    .title {
      text-transform: uppercase;
      font-size: 2rem;
      @include media-breakpoint-up(xxl) {
        font-size: 2.5rem;
      }
    }
    p {
      font-size: 1rem;
      line-height: 1.8;
    }
  }
  .skew-image {
    @include media-breakpoint-up(md) {
      width: 150%;
      transform: skew(-25deg);
      overflow: hidden;
      height: 100%;
    }
    img {
      @include media-breakpoint-up(md) {
        transform: skew(25deg) scale(1.5);
      }
    }
  }
}