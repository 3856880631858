.discounted-life-insurance {

  .hero {
    &.hero--page {
      @include media-breakpoint-up(md) {
        &:before {
          background: linear-gradient(90deg, #fff, #fff 10%, hsla(0, 0%, 100%, 0) 20%);
        }
      }
      .hero-bg > .bg {
        @include media-breakpoint-up(lg) {
          width: 90%;
        }
      }
    }
  }

  .large-video-overlay {
    display: none;
  }

  .video-block {
    .information {
      h2 {
        font-size: 2.5rem;
        text-transform: uppercase;
      }
      p {
        font-size: 1.25rem;
        line-height: 1.75;
      }
    }
  }
  .hidden {
    display: none;
  }
  .ajax-loader {
    background-image: url("../../assets/images/loader.svg");
    width: 2em;
    height: 2em;
    margin: 0;
    background-size: contain;
    position: absolute;
    left: 5%;
    top: calc(50% - 1em);
  }
}