
.appointment-page-wrapper {
  &.step-1 {
    display: none;
    &.active {
      display: block;
    }
    .appointment-option {
      height: calc(50vh - 26px);
      @include media-breakpoint-up(lg) {
        height: calc(100vh - 91px);
      }
      display: flex;
      align-items: center;
      text-align: center;
      position: relative;
      h3 {
        font-size: 18px;
        margin: 0 auto 15px;
        @include media-breakpoint-up(md) {
          font-size: 28px;
          margin: 0 auto 50px;
        }
        letter-spacing: 0.1em;
        line-height: 1.5;
        color: $black;
        width: 90%;
        max-width: 630px;
      }
      .image {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-size: cover;
        background-position: 100% 0%;
        @include media-breakpoint-up(lg) {
          background-position: center right;
        }
        pointer-events: none;
      }
      .inner {
        position: relative;
        z-index: 1;
        margin: 0 auto;
      }
      &.consultation {
        background: $brand;
        .image {
          opacity: 0.1;
        }
        .inner {
          h3 {
            color: white;
          }
        }
        a.btn {
          background-color: white;
          span {
            color: $brand;
          }
        }
      }
    }
  }
  &.step-2 {
    display: none;
    &.active {
      display: block;
    }
    padding: 75px 0;
    text-align: center;
    h4 {
      font-size: 18px;
      margin-bottom: 15px;
    }
    button {
      margin: 0 8px;
    }

  }
}

.page-template-cdv-appointment {
  footer.primary {
    display: none;
  }
}

.page-template-cdv-thankyou-appointment,
.page-template-cdv-arrangeconsultation,
.page-template-cdv-nonmember {
  footer.primary {
    .consultation {
      display: none;
    }
  }
}

.page-template-cdv-arrangeconsultation,
.page-template-cdv-nonmember {
  .hero {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  .talk-request-wrapper {
    padding-top: 0px;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      padding: 30px 0;
    }
  }

  .contactform-wrapper {
    .container-fluid {
      @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
      }

    }

    @include media-breakpoint-down(xs) {
      form .step-1-col span.wpcf7-list-item label {
        .wpcf7-list-item-label {
          padding-top: 10px;
          padding-left: 75px;
          height: 50px;
          margin-bottom: 15px;
          &:before {
            background-size: 30px;
            width: 50px;
            height: 50px;
          }
        }
        input {
          position: absolute;
          right: 10%;
          top: 15px;
        }

      }

    }

    .summary {

      @include media-breakpoint-down(xs) {
        background: $brand;
        margin-top: 0;
        margin-bottom: 0;
        border: 0;
        width: 200%;
        .hide-mob {
          display: none;
        }
        .row {
          transform: translate(12%);
          transition: transform .3s ease;
          &.step-1 {
            transform: translate(-14%);
          }

          &.step-2 {
            transform: translate(-38%);
          }

          &.step-3 {
            transform: translate(-63%);
          }
        }

        .cell {
          display: inline-block;
          width: 25%;
          padding-top: 20px;
          padding-bottom: 20px;
          position: relative;
          text-align: center;
          &:after {
            content: '';
            position: absolute;
            height: 6px;
            background: white;
            bottom: 0;
            width: 60%;
            left: 25%;
          }
          span {
            color: white;
            text-decoration: none !important; //over-riding :not()
          }
          span.submitted-data,
          .submitted-time,
          .submitted-date {
            display: none;
          }
        }
      }

      .heading-area {
        display: none;
        @include media-breakpoint-up(sm) {
          display: block;
        }
      }

    }

  }

  .step-mobile {
    padding: 30px 60px;
    @include media-breakpoint-up(sm) {
      display: none;
    }

    .h5 {
      color: $brand;
    }
  }

}
