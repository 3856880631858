.membership-code {
  padding: 0;
  .code-output {
    margin: 0 0 20px;
    input {
      width: 100%;
      text-align: center;
      display: flex;
      background: rgba($brand, .25);
      align-items: center;
      justify-content: center;
      color: #000;
      pointer-events: none;
      margin: 0 !important;
    }
  }
  .copy-button {
    margin: 0 0 20px;
  }
}