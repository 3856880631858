.video-block {
  margin: 50px auto 0;
  padding-left: 0;
  padding-right: 0;

  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

    .row {
      flex-direction: column-reverse;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
  }

  .video-container {
    width: 100%;

    &__video {
      width: 100%;
    }
  }

  .information {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-heading {
      margin-bottom: 30px;
      font-size: 2rem;

      @include media-breakpoint-up(sm) {
        font-size: 2.7em;
      }
    }

    &-text {
      padding-right: 25px;
      font-size: 14px;
      line-height: 1.5;

      @include media-breakpoint-up(sm) {
        line-height: 1.7;
        font-size: 17px;
      }
    }
  }
}

.large-video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 30px;

    &__img {
      fill: white;
      z-index: 10001;
      display: block;
    }
  }

  &__video {
    max-width: 100%;
    max-height: 80vh;
  }

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
}
