/* Styling for the Page link blocks component */
.page-block-links {
  padding-top:40px;
}
.page-block-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  overflow: hidden;
  position: relative;
  background: #000;
  height: 500px;
  margin-bottom: 20px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
  .content {
    position: relative;
    z-index: 2;
    text-align: center;
    .title {
      color: #fff;
      font-family: $font-secondary;
      text-shadow: 2px 2px 10px rgba(#000, .25);
      font-size: 3rem;
    }
    .text {
      text-transform: uppercase;
      margin: 20px 0;
      color: #fff;
    }
  }
  .block-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    opacity: .6;
  }
}