/* Styling for the Accordions component */
.accordions-block {
  padding: 20px;
  .accordion-item {
    text-align: left;
    margin-top: 40px;
    padding-right: 40px;
    position: relative;
    cursor: pointer;
    display: block;
    width: 100%;
    &:after {
      content: '\e908';
      font-family: 'icomoon' !important;
      color: $brand;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.5rem;
      display: block;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    @include media-breakpoint-up(lg) {
      padding-right: 80px;
    }
    &:first-of-type {
      margin-top: 0;
    }
    .title {
      display: block;
      width: 100%;
      text-transform: uppercase;
    }
    .content {
      margin-top: 20px;
      display: none;
    }
    &.active {
      &:after {
        content: '\ea0b';
      }
    }
  }
}