/* ==========================================================================
   
   DISCOVER MASONRY

========================================================================== */

header.title-button {

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-bottom: 40px;

	@include media-breakpoint-up(lg) {

		flex-direction: row;
		margin-bottom: 60px;

	}

	.title {

		font-size: 38px;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: $brand;
		margin-bottom: 0;

		@include media-breakpoint-up(lg) {

			font-size: 45px;

		}

	}

	a {

		display: inline-block;
		margin-top: 30px;

		@include media-breakpoint-up(lg) {

			margin-top: 0;
			margin-left: 80px;

		}

	}

}

.discover-masonry {
	
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;

	@include media-breakpoint-up(lg) {

		flex-direction: row;
		flex-wrap: wrap;

		max-width: 2000px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 60px;

	}

	// Animate
	@include media-breakpoint-up(xl) {

		.masonry-item {

			transition: transform 0.6s ease-in-out;

			&:first-child {

				transform: translateX(-50px) skew(-30deg);

			}

			&:nth-child(3) {
				
				transform: translateX(50px) skew(-30deg);

			}

		}

		&.aos-animate {

			.masonry-item {

				&:first-child {

					transform: translateX(0) skew(-30deg);

				}

				&:nth-child(3) {
					
					transform: translateX(0) skew(-30deg);

				}

			}

		}
		
	}

}

.masonry-item {

	position: relative;
	height: 340px;
	flex: 1 0 auto;
	width: 100%;

	padding-left: 0;
	padding-right: 0;
	margin-bottom: 16px;

	overflow: hidden;

	@include media-breakpoint-up(lg) {
		
		height: 400px;
		width: 50%;
		transform: skew(-30deg);
		margin-left: 8px;
		margin-right: 8px;

	}

	@include media-breakpoint-up(xl) {

		width: calc(100% / 3);

	}

	@media (min-width: 1400px) {

		height: 500px;

	}

	@include media-breakpoint-up(xxl) {

		width: 30%;

	}

	&:last-child {

		margin-bottom: 0;

	}

	&:nth-last-child(-n+3) {

		@include media-breakpoint-up(lg) {

			margin-bottom: 0;

		}

	}

	&:nth-child(1),
	&:nth-child(4) {

		@include media-breakpoint-up(xl) {

			margin-left: -10%;

		}

		.masonry-content {

			@include media-breakpoint-up(xl) {

				padding: 0 5% 0 20%;

			}

			@media (min-width: 1400px) {

				padding: 0 5% 0 24%;
			
			}

		}

	}

	&:nth-child(3),
	&:nth-child(6) {

		@include media-breakpoint-up(xl) {

			margin-right: -10%;

		}


		.masonry-content {

			@include media-breakpoint-up(xl) {

				padding: 0 20% 0 10%;
				
			}

			@media (min-width: 1400px) {

				padding: 0 24% 0 10%;
			
			}

		}

	}

	&:nth-child(2),
	&:nth-child(5) {


		@include media-breakpoint-up(xl) {

			width: calc(100% / 3);

		}

		@include media-breakpoint-up(xxl) {

			width: 40%;

		}

		.masonry-bg {

			@include media-breakpoint-up(xl) {

				width: 150%;
				margin-left: -25%;

			}

			@media (min-width: 1400px) {

				width: 152%;
				margin-left: -26%;
				
			}

			@include media-breakpoint-up(xxl) {
				
				width: 136%;
				margin-left: -18%;

			}

		}

	}

	&:nth-child(even) {

		@include media-breakpoint-between(lg, lg) {

			margin-right: -12%;

		}

	}

	&:nth-child(odd) {

		@include media-breakpoint-between(lg, lg) {

			margin-left: -12%;
			
		}

	}

	@include media-breakpoint-up(xl) {

		&:hover { 

			.masonry-inner {

				background-color: rgba(0, 0, 0, 0.8);

				.title-preview {

					transform: translateY(-50%) scale(0.7) skew(30deg);
					opacity: 0;

				}
				
			}

			.masonry-content {

				transform: translateY(0) skew(30deg);
				visibility: visible;
				opacity: 1;

			}

		}
		
	}

	.masonry-inner {

		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;

		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 1;

		transition: all 0.5s ease-in-out 0.1s;

		@include media-breakpoint-up(xl) {
			
			background-color: rgba(0, 0, 0, 0.2);

		}

		.title {

			text-transform: uppercase;
			letter-spacing: 0.2em;
			font-size: 20px;
			line-height: 27px;
			margin-bottom: 18px;
			color: $white;

			@include media-breakpoint-up(xl) {

				margin-bottom: 30px;

			}

		}

		.title-preview {

		    position: absolute;
		    top: 50%;
		    right: 0;
		    left: 0;

		    margin: 0 auto;
		    width: 100%;
		    max-width: 42%;

		    transition: all 0.6s ease-in-out;
		    text-align: center;
		    margin-bottom: 0;
		    display: none;

		    @include media-breakpoint-up(xl) {

			    display: block;
			    transform: translateY(-50%) skew(30deg) scale(1);

		    }
 
		}

	}

	.masonry-content {

		position: relative;
		color: white;

		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: 0 5vw;

		@include media-breakpoint-up(lg) {

			transform: translateY(0) skew(30deg);
			visibility: visible;
			opacity: 1;
			padding: 0 18%;
			margin: 0 8%;

		}

		@include media-breakpoint-up(xl) {

			transform: skew(30deg) translateY(-20px);
			opacity: 0;
			visibility: hidden;
			transition: all 0.4s ease-in-out;
			padding: 0 18%;

		}

		p {

			font-size: 15px;
			line-height: 28px;

			@include media-breakpoint-up(xl) {

				line-height: 45px;

			}

		}

	}
	
	.masonry-bg {

		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		width: 100%;
		height: 100%;

		@include media-breakpoint-up(lg) {

			transform: skew(30deg);
			width: 150%;
			margin-left: -25%;

		}

	}

}