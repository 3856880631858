// All !important declarations are for overriding legacy styling :'(
.consultation {
  form {
    background: transparent;
  }
  &__summary,
  &__form,
  form {
    margin: auto;
  }
  &__form,
  &__summary {
    background-color: $white;
  }
  &__form {
    padding-top: 50px;
    .personal-dob {
      max-width: 50px;
      margin-right: 15px !important;
      display: inline-block;
      @include media-breakpoint-down(sm) {
        max-width: 70px;
      }

      .wpcf7-not-valid-tip {
        display: inline-block;
      }
    }
    .main-title {
      font-size: 1.3rem !important;
      text-transform: uppercase;
    }
    .js-slider-message {
      display: none;
    }
  }

  &__summary .cell {
    @include media-breakpoint-up(lg) {
      padding: 30px !important;
    }

    > span:not(.submitted-data):not(.submitted-date):not(.submitted-time):not(.date-time-placeholder):not(.hide-mob) {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .step-1-col {
    .main-title {
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(sm) {
      .input-checkbox-wrap {
        width: 100%;
      }
      .home-input, .work-input, .office-input {
        display: flex;
      }
      .radio-location {
        display: block;
        .wpcf7-list-item-label {
          font-size: 16px;
        }
      }
    }
    .address-wrap {
      margin: 0;
    }
  }

  .step-3-col {
    .financial-title {
      margin: 0 !important;
      font-size: 1rem;
    }
    .step-3__subtitle {
      font-size: 90% !important;
    }
  }

  .checkbox-block {
    display: inline-block;

    .select-blocks {
      @include media-breakpoint-only(md) {
        display: inline-block;
      }
      .wpcf7-checkbox {
        width: auto;
      }
    }

    .wpcf7-list-item {
      padding: 20px;
      width: 100%;

      label.active {
        color: $brand;
      }

      &-label {
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }

  .consultation-block {
    margin-top: 50px;

    label {
      color: $grey-dark;
      &.active {
        color: $brand;
      }
      .wpcf7-list-item-label {
        margin-left: 0 !important;
      }
    }

    &-label {
      font-size: 16px;
    }

    &__description {
      padding-top: 20px;
    }
  }

  &__disclaimer {
    font-size: 16px;
    color: $brand;
  }

  .personal-title {
    display: block;
  }

  input[type='checkbox']:not([name="terms"]) {
    position: absolute;
    left: -9999px;

    & + .wpcf7-list-item-label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;

      &:before {
        content: '';
        border-radius: 9px;
        width: 18px;
        height: 18px;
        margin: 2px;
        border: thin solid grey;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: 6px;
        left: 6px;
        width: 10px;
        height: 10px;
        border-radius: 9px;
      }
    }

    &:checked {
      & + .wpcf7-list-item-label {
        &:after {
          background: $brand;
        }
      }
    }
  }

  .advice-requests .wpcf7-checkbox {
    @include media-breakpoint-only(md) {
      width: auto;
    }
  }

  .advice-requests, .select-blocks {
    .wpcf7-list-item-label {
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-size: 14px;
    }
  }

  #location-target {
    margin-top: 20px;
    text-transform: uppercase;
  }

  .js-slider-message {
    color: $warn;
  }

  .wpcf7-acceptance-missing {
    display: none !important; // Hide default validation message, this is handled by custom validation.
  }
}
