/* ==========================================================================
   
   MIXINS
   
========================================================================== */

/* LOOP THROUGH HEADRS
========================================================================== */
@function headings($from:1, $to:6) {
    @if $from == $to {
        @return 'h#{$from}';
    } @else {
        @return 'h#{$from},' + headings($from+1, $to);
    }
}

/* ICONS
========================================================================== */

@mixin icons() {

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}