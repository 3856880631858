$module-padding: 100px;

.partnerships-module {
  padding-top: $module-padding;
  padding-bottom: $module-padding;
  background: $white-alt;
  margin: 0 !important; //override the container fluid margin for this one component
  @media (max-width: 540px) {
    padding-top: $module-padding / 2;
    padding-bottom: $module-padding / 2;
  }
}

.partnerships-module__title {
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: $font-size-large-title;
  @media (max-width: 540px) {
    font-size: $font-size-medium;
    text-align: center;
  }
}

$y-padding: 40px;

.partnerships-module__body {
  margin-top: $y-padding;
  margin-bottom: $y-padding;
  line-height: 2.5;
  display: flex;
  @media (max-width: 540px) {
    width: 100%;
  }
}

.partnerships-module__body-content {
  flex: 1;
  padding-right: 40px;
  @media (max-width: 540px) {
    padding-right: 0px;
  }
}

.partnerships-module__body-top-border {
  flex: 1;
  border-top: 1px solid darken($white-alt, 10%);
  @media (max-width: 992px) {
    flex: 0;
  }
}

.partnership__tile-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.partnership__tile {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 340px;
  margin-bottom: 34px;
  flex-basis: 32%;
  @media (max-width: 720px) {
    flex-basis: 49% !important; //without this, the width is not overridden;
    height: 100px;
    margin-bottom: 12px;
  }
}

.partnership__tile--wide {
  flex-basis: 49%;
}

.partnership__tile-image {
  height: 50%;
}