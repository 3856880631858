/* ==========================================================================
   
   PAGE 

========================================================================== */

.page-block {

	&.light-bg {

		background: $white-alt;
		padding: 30px 0;
		margin-bottom: 50px;

		@include media-breakpoint-up(md) {

			padding: 60px 0;

		}

		@include media-breakpoint-up(lg) {

			margin-bottom: 0;
			
		}

		&.no-m-b {

			margin-bottom: 0;

		}

		.block-title {
			
			margin-bottom: 25px;
			text-align: center;

			@include media-breakpoint-up(md) {

				display: flex;
				flex-direction: column;

				flex-direction: row;
				align-items: center;
				text-align: left;

			}

			&.has-btn {

				@include media-breakpoint-up(xl) {

					margin-bottom: 55px;
					
				}

			}

			.title {

				color: $black;
				font-size: 30px;
				font-weight: normal;
				letter-spacing: 0.2em;
				text-transform: uppercase;

				@include media-breakpoint-up(lg) {
					font-size: 36px;
					line-height: 1.3em;
				}
				@include media-breakpoint-up(xl) {
					font-size: 45px;
				}

			}

			.btn {

				margin-top: 25px;

				@include media-breakpoint-up(md) {
					
					margin-top: 0;
					margin-left: auto;

				}

				@include media-breakpoint-up(xl) {

					min-width: 320px;

				}

			}

		}

	}

	&.title-divider {

		.title {

			font-size: 28px;
			text-transform: uppercase;
			text-align: center;
			letter-spacing: 0.2em;

			margin-top: 30px;
			margin-bottom: 40px;

			@include media-breakpoint-up(lg) {
				font-size: 36px;
				line-height: 1.3em;
			}
			@include media-breakpoint-up(xl) {
				font-size: 45px;
				margin-bottom: 50px;
			}

		}

	}

}

.content-block {

	.icon {

		max-width: 80px;
		margin-bottom: 20px;

		@include media-breakpoint-up(lg) {

			max-width: 130px;
			margin-bottom: 30px;

		}

	}

	.title {

		color: $brand;
		font-size: 28px;
		line-height: 45px;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		margin-bottom: 30px;

		@include media-breakpoint-up(lg) {
			font-size: 36px;
			line-height: 1.3em;
		}
		@include media-breakpoint-up(xl) {
			font-size: 45px;
			margin-bottom: 45px;
		}



	}

	.btn {	

		display: block;
		margin-top: 25px;
		margin-left: auto;
		margin-right: auto;
		max-width: 260px;

		@include media-breakpoint-up(lg) {

			margin-top: 50px;
			display: inline-block;
			max-width: 100%;

		}
		
	}

	p {

		&.small-width {

			@include media-breakpoint-up(lg) {

				max-width: 80%;
				margin-left: auto;
				margin-right: auto;

			}

		}

	}

}

.number-satisfaction {

	@extend .list-unstyled;
	margin-top: 30px;

	@include media-breakpoint-up(xl) {

		margin-top: 0;

	}

	li {

		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-evenly;

		@include media-breakpoint-up(xl) {

			flex-wrap: nowrap;
			justify-content: flex-start;

		}

		&:not(:last-child) {

			margin-bottom: 25px;

			@include media-breakpoint-up(xl) {

				margin-bottom: 50px;

			}

		}

		& > span {

			margin-bottom: 0;

		}

		&.ie-border .circle:before {

			border-width: 6px;

		}

	}

	.number {

		font-size: 45px;
		color: $brand;
		letter-spacing: 0.1em;

		@include media-breakpoint-up(xl) {

			min-width: 40%;

		}

	}

	.icon {

		font-size: 32px;
		border: 8px solid $brand;
	    border-radius: 100%;
	    display: inline-block;
	    width: 78px;
	    height: 78px;
	    padding: 15px;
	    margin-right: 40px;

	}

	.circle {

		min-width: 78px;
		min-height: 78px;
		display: inline-block;
		position: relative;

		@include media-breakpoint-up(xl) {

			margin-right: 40px;

		}

		&:before {

			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 8px solid #DFE2E5;
			border-radius: 100%;
			width: 78px;
			height: 78px;
			z-index: -1;

		}

		&:after {

			@include icons;
			content: '\e908';

			position: absolute;
			font-size: 33px;
			top: calc(50% - 5px);
			left: 50%;
			transform: translate(-50%, -50%);
			color: #DFE2E5;
			transition: color 2.5s ease-in-out;

		}

		&.thumb:after {

			content: '\e90b';

		}

		&.face:after {

			content: '\e90a';
			top: calc(50% - 2px);

		}

		&.active:after {

			color: $brand;

		}

		svg {

			width: 78px;
			height: 78px;
			display: inline-block;

		}

	}

	.text {

		font-size: 15px;
		margin-top: 10px;
		width: 100%;

	    @include media-breakpoint-up(xl) {

	    	margin-top: 0;
	    	width: auto;
	    	
	    }

	}

}

.number-list {

	@extend .list-unstyled;

	.list-item {

		margin-bottom: 30px;

		.number {
			
			color: $white;
			font-size: 50px;
			font-family: $font-secondary;
			text-align: center;
			font-weight: $font-bold;
			background: $brand;
			border-radius: 100%;

			width: 70px;
			height: 70px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;

			@include media-breakpoint-up(lg) {

				margin-left: 0;
				margin-right: 0;

			}

		}

		.desc {

			font-size: 15px;
			
			@include media-breakpoint-up(lg) {

				font-size: 17px;
				line-height: 1.8;

			}

		}

	}

}

.page-search {

	margin-bottom: 40px;

	@include media-breakpoint-up(xl) {

		margin-bottom: 80px;
		margin-left: 60px;
		
	}

	&.page-search-post {

		margin-top: 40px;

		@include media-breakpoint-up(xl) {

			margin-bottom: 40px;

		}
		
	}

	.page-search-title {

		color: $brand;
		font-size: 34px;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		margin-bottom: 60px;

		@include media-breakpoint-up(lg) {

			font-size: 45px;

		}

	}

	.form-default {

		display: flex;
		flex-wrap: wrap;
		align-items: center;
		flex-direction: column;

		@include media-breakpoint-up(lg) {

			flex-direction: row;

		}

		.refine {

			color: $brand;
			text-transform: uppercase;
			letter-spacing: 0.2em;
			font-size: 20px;
			display: block;
			margin-bottom: 20px;

			@include media-breakpoint-up(lg) {

				margin-bottom: 0;

			}

		}

		.form-input {

			width: 100%;
			margin-left: 15px;
			margin-right: 15px;
			margin-bottom: 20px;

			@include media-breakpoint-up(lg) {

				width: calc(25% - 30px);
				margin-bottom: 0;

			}

			input,
			select {

				width: 100%;

			}

		}

	}

}

.form-default {

	.ico-search {

		@include media-breakpoint-up(xl) {

			width: 32px;
			position: relative;
			left: -7px;
			top: 5px;

		}
		
	}

	input,
	select {

		height: 70px;
		border: 1px solid $border-color;
		color: #000;
		font-size: 18px;
		background: $white;
		padding-left: 25px;
		border-radius: 0;

		@include media-breakpoint-up(xl) {

			padding-left: 45px;

		}

		&:focus {

			outline: none;
			box-shadow: none;

		}

	}

	select {

		-webkit-appearance: none;
		-moz-appearance: none;

		&::-ms-expand {

			display: none;

		}

	}

	.form-input {

		position: relative;

		&.form-select {
			
			&:before {

				content: '\e901';
				@include icons;
				color: $brand;

				position: absolute;
				top: 50%;
				right: 30px;
				transform: translateY(-50%);
				
			}

		}

	}

	.form-submit {

		position: relative;
		overflow: hidden;

		@include media-breakpoint-up(lg) {

			overflow: visible;
		}

		input {

			padding-right: 50px;

			@include media-breakpoint-up(xxl) {

				padding-right: 175px;
				
			}

		}

		.btn {

			position: absolute;
			top: 0;
			right: -20px;
			padding-left: 30px;
			padding-right: 40px;
			max-height: 70px;
			height: 100%;

			@include media-breakpoint-up(lg) {

				padding-right: 30px;

			}

			.icon:before {

				font-size: 26px;

			}

		}

		img {

			@include media-breakpoint-up(xl) {

				position: relative;
				top: -10px;

			}

		}

	}

}

header.thank-you {

	text-align: center;
	margin-top: 50px;
	margin-bottom: 50px;

	.title {

		font-size: 28px;
		line-height: 1.3;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		margin-bottom: 15px;

		@include media-breakpoint-up(lg) {
			font-size: 36px;
			line-height: 1.3em;
		}
		@include media-breakpoint-up(xl) {
			font-size: 45px;
		}

	}

}

.thank-you-details {

	border: 1px solid #D6D6D6;
	margin-bottom: 40px;

	@include media-breakpoint-up(lg) {

		margin-bottom: 95px;

	}

	header {
		
		background: $brand;
		padding: 28px 10px;
		text-align: center;

		p {

			color: $white;
			text-transform: uppercase;
			letter-spacing: 0.2em;
			margin-bottom: 0;

		}

	}

	.details {

		@extend .list-unstyled;
		padding: 0 40px;
		margin-top: 30px;
		margin-bottom: 30px;

		@include media-breakpoint-up(sm) {

			padding: 0 20px;
			text-align: center;
			margin-top: 55px;
			margin-bottom: 45px;

		}

		li {

			color: $brand;
			display: block;

			@include media-breakpoint-up(sm) {

				display: inline-block;
				padding: 0 20px;

			}

			@include media-breakpoint-up(xl) {

				padding: 0;
			
			}

			&:not(:last-child) {

				margin-bottom: 15px;

				@include media-breakpoint-up(sm) {


				}

				@include media-breakpoint-up(lg) {

					margin-right: 50px;

				}

			}

			span {

				color: $grey-dark;

			}

		}

	}

}

.page-template-cdv-about{
}


.page-template-cdv-thankyou-appointment {
	.brand-banner-alt {
		margin-bottom: 0;
	}
}
.page-template-cdv-thankyou{
    footer{
		.consultation{
			display: none;
		}
	}
}

.page-template-cdv-services-inner-child{
	.services-container+.page-block{
		margin: 50px 0;
	}
}

.post-type-archive-events{
	.page-block.brand-banner.has-bg{
		display: none;
	}
}

body.our-offices{
	.container-fluid{
		.col-12.col-md-6.col-xl-3{
			height: 210px;
			overflow: hidden;
		}
	}
}
