/* Styles for the Gallery Topics component */
.gallery-topics {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #000;

  .bootstyle {
    padding: 5px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &.black {
    background-color: black;
  }

  &.dark-grey {
    background-color: $grey-dark;
  }

  &.grey {
    background-color: $grey-darker;
  }

  &.light-grey {
    background-color: $light-grey;

    .title.block-header,
    .title-supporting {
      color: $grey-darker !important;
    }
  }

  .title {
    color: #fff;
    text-align: center;
    margin-bottom: 50px;
    padding-top: 50px;
    text-transform: uppercase;
    position: relative;

    &:before {
      content: '';
      background: $brand;
      opacity: 1;
      width: 60px;
      height: 2px;
      position: absolute;
      margin: 0 auto;
      top: 0;
      left: 0;
      right: 0;
      display: block;
    }

    &.remove-line {
      &:before {
        display: none;
      }
    }
  }

  .title-supporting {
    color: #fff;
    font-size: 14px;
    margin: 0 auto 50px;
    text-align: center;
    display: block;
    @include media-breakpoint-up(lg) {
      font-size: 16px;
      max-width: 80%;
    }
  }

  .gallery-topic {
    width: 100%;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    position: relative;
    overflow: hidden;
    background: #000;
    height: 400px;

    @include media-breakpoint-up(xl) {
      height: 560px;
    }

    .background-image {
      background-size: cover;
      background-position: center center;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: .5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: all .5s ease-in-out;
    }

    &.has-icon {
      .icon, .topic-icon {
        display: block;
      }

      .content {
        margin-top: -30px;

        @include media-breakpoint-up(md) {
          margin-top: 50px;
        }

        @include media-breakpoint-up(xl) {
          margin-top: 30px;
        }

        &:after {
          display: none;
        }
      }
    }

    .content {
      position: relative;
      z-index: 10;
      margin-top: 0;

      @include media-breakpoint-up(md) {
        margin-top: 50px;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 30px;
      }

      @include media-breakpoint-up(xxl) {
        margin-top: 0;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        height: 50px;
        width: 1px;
        background-color: $light;
        opacity: 0;
        transition: 0.5s;
      }

      &:before {
        top: 100%;
        transform: translateY(50px);
      }

      &:after {
        bottom: 100%;
        transform: translateY(-50px);
      }
    }

    .icon, .topic-icon {
      height: 40px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      transition: 0.6s;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: none;
      width: 100%;
      color: #fff;
      text-align: center;
      font-size: 40px;
      position: absolute;
      transform: translateY(-10px);

      + .title {
        transform: translateY(50px);
        transition: transform .5s ease-in-out;
      }
    }

    .title {
      text-transform: uppercase;
      margin: 0;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 20px;
      letter-spacing: 2px;
      padding-top: 0;

      &:before {
        display: none;
      }

      @include media-breakpoint-up(md) {
        &.animate-with-p {
          transform: translateY(50px);
          transition: transform .5s ease-in-out;
        }
      }
    }

    p {
      display: none;
      @include media-breakpoint-up(md) {
        color: #fff;
        max-width: 380px;
        width: 100%;
        text-align: center;
        margin: 20px auto 0;
        transform: translateY(200px);
        opacity: 0;
        display: block;
        transition: all .5s ease-in-out;
        font-size: 15px;
        line-height: 1.7;
      }
    }

    &:hover {
      @include media-breakpoint-up(md) {
        p {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .title {
        @include media-breakpoint-up(md) {
          &.animate-with-p {
            transform: translateY(0);
          }
        }
      }

      .icon, .topic-icon {
        transform: translateY(-60px);

        + .title {
          transform: translateY(0px);
        }
      }

      .background-image {
        transform: scale(1.05);
      }

      .learn-more {
        .learn-more-text {
          transform: translateX(0);
        }
      }

      .content {
        &:before,
        &:after {
          opacity: 1;
          transition-delay: 0.35s;
        }

        &:before {
          transform: translateY(25px);
        }

        &:after {
          transform: translateY(-25px);
        }
      }
    }

  }

  &.career-types {
    .gallery-topic {
      @extend .d-flex;
      @extend .align-items-start;
      @extend .justify-content-start;

      .content {
        padding: 40px;
        margin: 0;

        .title {
          padding: 0;
          text-align: left;
          letter-spacing: 1px;
          @include media-breakpoint-up(xl) {
            font-size: 30px;
            letter-spacing: 3px;
          }
        }

        .career-type-cats {
          display: none;

          @include media-breakpoint-up(xl) {
            display: block;
          }

          padding-top: 20px;

          p {
            transform: translateY(0);
            display: block;
            opacity: 1;
            text-align: left;
            margin: 0;
            padding-left: 20px;

            &:before {
              content: '';
              width: 5px;
              height: 5px;
              display: block;
              background: #fff;
              border-radius: 50%;
              position: absolute;
              top: 10px;
              left: 0;
            }
          }
        }

        &:before {
          display: none;
        }
      }

      .career-count {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 40px;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 1px;
        z-index: 10;
        @include media-breakpoint-up(xl) {
          font-size: 20px;
          letter-spacing: 3px;
        }

        &:after {
          content: '';
          background: $brand;
          opacity: 1;
          width: 60px;
          height: 2px;
          position: absolute;
          bottom: 30px;
          left: 40px;
          display: block;
        }
      }

      .background-image {
        opacity: .5;

      }

      height: 250px;
      @include media-breakpoint-up(xl) {
        height: 450px;
      }
    }
  }

  &.downloads {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .bootstyle {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
