/* ===============gst===========================================================
   
   TYPE

========================================================================== */

/* UTIL
========================================================================== */

.text-brand {

	color: $brand!important;
	
}

.text-black {

	color: $black!important;

}

/* HEADINGS
========================================================================== */
#{headings(1,6)} {

	color: $heading-color;

}

/* PARAGRAPH
========================================================================== */

p {

	font-size: 15px;
	line-height: 25px;
	font-family: $font-base;

	@include media-breakpoint-up(xxl) {

		font-size: 17px;
		line-height: 1.7;

	}
	
}

ul, ol {

	li {

		font-size: 15px;

		@include media-breakpoint-up(xxl) {
			font-size: 17px;
		}

	}
	
}

/* Links
========================================================================== */

a {

	text-decoration: none;
	transition: color 0.3s ease-in-out;
	color: $brand;

	&:hover {

		text-decoration: none;
		color: $brand-light;

	}

	&:focus {

		outline: none;

	}


}

.cat-link {
	
	border: 1px solid $brand;
	color: $brand;
	text-align: center;
	padding: 10px 30px;
	font-size: 14px;
	display: inline-block;

	@include media-breakpoint-up(md) {

		font-size: 19px;

	}

}