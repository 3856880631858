/* ==========================================================================
   
   FORMS

========================================================================== */

form {

  input {

    &:focus {

      outline: none;

    }

  }

}

.theme-error {
  color: $warn;
  font-family: $font-base;
  font-size: 16px;
}