/* ==========================================================================
   
   GLOBAL

========================================================================== */

body {
  background: $white;
  color: $text-color;
  font-family: $font-base;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.wrap {
  position: relative;
  overflow: hidden;
}

.container-fluid {
  max-width: 1800px;
  padding-left: 5vw;
  padding-right: 5vw;
}

img {
  max-width: 100%;
  height: auto;
}

// Util
.no-pad-md {
  @include media-breakpoint-down(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

// slick
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  li {
    background: transparent;
    border: 1px solid $white;
    border-radius: 100%;
    width: 10px;
    height: 10px;

    &.slick-active {
      background: $white;
    }
  }

  button {
    &:before {
      display: none;
    }
  }
}

.hero-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  > .bg {
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(30px);

    &.loaded {
      animation: heroBg 2s cubic-bezier(0.02, 0.5, 0.99, 1) forwards;
    }

    @include media-breakpoint-up(sm) {
      width: 55%;
    }


    @include media-breakpoint-up(lg) {
      width: 50%;
    }


    &.mobile {
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }

    &.desktop {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}

.wufoo-loading {
  text-align: center;
  padding: 5rem;
}