/* ==========================================================================
   
   BLOCK SERVICE

========================================================================== */

.block-banner {
  @include media-breakpoint-up(lg) {
    width: 60%;
    overflow: hidden;
    margin-left: -13%;
  }


  @include media-breakpoint-up(xl) {
    width: 50%;
  }


  .banner-wrap {
    position: relative;
    min-height: 230px;
    margin-left: -5vw;
    margin-right: -5vw;
    margin-bottom: 35px;

    @include media-breakpoint-up(sm) {
      min-height: 450px;
    }


    @include media-breakpoint-up(lg) {
      transform: skew(-30deg);
      overflow: hidden;
      margin-left: -15%;
      margin-right: 24%;
    }


    @include media-breakpoint-up(xl) {
      min-height: 660px;
      margin-left: -30%;
      margin-right: 30%;
    }


    @include media-breakpoint-up(xxl) {
      min-height: 960px;
      margin-left: -31%;
      margin-right: 31%;
    }


    .banner-inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        transform: skew(30deg);
      }


      &:after {
        content: '';
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);

        @include media-breakpoint-up(lg) {
          left: auto;
          right: 50px;
          transform: skew(-30deg);
        }
      }

      .inner-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 3;

        @include media-breakpoint-up(lg) {
          margin-left: 20%;
        }


        @include media-breakpoint-up(xl) {
          margin-left: 45%;
        }
      }

      .title {
        color: $white;
        font-size: 25px;
        font-family: $font-secondary;

        @include media-breakpoint-up(lg) {
          font-size: 36px;
          line-height: 1.3em;
        }


        @include media-breakpoint-up(xl) {
          font-size: 45px;
        }


        @include media-breakpoint-up(xxl) {
          font-size: 60px;
        }
      }

      .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;

        @include media-breakpoint-up(lg) {
          width: 100%;
          margin-left: 30%;
        }


        @include media-breakpoint-up(xl) {
          height: calc(100% - 60px);
          margin-left: 36%;
        }
      }
    }
  }
}

.block-service {
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }

  .block-banner {
    @include media-breakpoint-up(lg) {
      width: 60%;
    }

    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    .banner-wrap {
      opacity: 0;
      transition: opacity 0.6s ease-in-out;

      &.aos-animate {
        opacity: 1;
      }

      @include media-breakpoint-only(xs) {
        opacity: 1;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  .service-selection {
    @include media-breakpoint-up(xxl) {
      margin-right: -30%;
      margin-left: -5%;
      max-width: 1100px;
      width: 70%;
    }
  }
}

.service-selection {
  @include media-breakpoint-up(sm) {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up(lg) {
    max-width: 50%;
    margin-left: 0;
    margin-right: 0;
  }

  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .selection-list {
    flex: 1 0 auto;

    @include media-breakpoint-up(xl) {
      order: 2;
      margin-right: 20%;
    }

    @include media-breakpoint-up(xxl) {
      margin-right: 10vw;
    }
  }

  .selection-content {
    min-height: 200px;

    @include media-breakpoint-up(xl) {
      padding-right: 60px;
      flex: 1 0 50%;
    }
  }

  .selection-list .list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    list-style-type: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
      text-align: center;
    }

    @include media-breakpoint-up(lg) {
      text-align: right;
    }

    @include media-breakpoint-up(xl) {
      flex-direction: column;
    }

    li {
      font-size: 14px;
      color: $brand;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      margin-bottom: 15px;
      width: calc(50% - 10px);
      margin-left: 5px;
      margin-right: 5px;

      @include media-breakpoint-up(xl) {
        font-size: 20px;
        margin-bottom: 25px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        transform-origin: right;
        transition: transform 0.3s ease-in-out;
      }

      @include media-breakpoint-down(lg) {
        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }

        &:nth-child(even).active a:before {
          left: auto;
          right: -40px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        @include media-breakpoint-up(xl) {
          transform: scale(1.2);
        }
      }

      &.active {
        @include media-breakpoint-up(xl) {
          transform: scale(1.2);
        }

        a {
          color: $black;

          &:before {
            content: '';
            background: $brand;
            height: 2px;
            width: 20px;
            position: absolute;
            top: calc(50% - 2px);
            transform: translateY(-50%);
            left: -40px;

            @include media-breakpoint-up(xl) {
              left: auto;
              right: -120px;
              width: 100px;
            }
          }
        }
      }
    }

    a {
      position: relative;
      color: $brand;
    }
  }

  .selection-content .content {
    text-align: center;
    display: none;

    @include media-breakpoint-up(xl) {
      text-align: left;
    }


    &.active {
      display: block;
    }

    p {
      line-height: 25px;

      @include media-breakpoint-up(xl) {
        line-height: 35px;
      }

      @include media-breakpoint-up(xxl) {
        line-height: 40px;
      }
    }

    .btn {
      margin-top: 30px;
    }
  }
}
