.tax-and-nhs-pensions {
  footer .consultation,
  .hero.hero--page .sub-title {
    display: none;
  }

  .bg.loaded:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(450deg, white 10%, transparent);
  }

  .btn {
    font-size: 0.8rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.1rem;
    }
  }

  .hero {
    &.hero--page-sm .hero-content {
      @include media-breakpoint-up(lg) {
        padding-top: 100px;
        padding-bottom: 250px;
      }
    }

    &.hero--page-xs .hero-content {
      @include media-breakpoint-up(lg) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    .hero-bg {
      position: relative;

      @include media-breakpoint-up(md) {
        position: absolute;
      }


      > .bg {
        background-position: center;
        position: relative;
        width: 100%;
        padding-top: 56%;

        &.loaded:after {
          display: none;
        }

        @include media-breakpoint-up(md) {
          width: 50%;
          padding-top: 0;
          position: absolute;

          &.loaded:after {
            display: block;
          }
        }
      }
    }

    .title {
      font-size: 30px;
      line-height: 41px;
      color: $black;
      text-transform: uppercase;
      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;
      animation-delay: 0.2s;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        font-size: 55px;
        line-height: 1.3em;
        max-width: 73%;
        margin-bottom: 50px;
      }


      &.title-sm {
        @include media-breakpoint-up(md) {
          font-size: 45px;
          line-height: 61px;
        }
      }
    }

    .desc {
      font-size: 14px;
      color: $text-color;
      max-width: 100%;
      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;
      animation-delay: 0.4s;
      line-height: 1.8;

      @include media-breakpoint-up(sm) {
        line-height: 2.2;
      }


      @include media-breakpoint-up(md) {
        max-width: 500px;
        font-size: 15px;
      }


      @include media-breakpoint-up(lg) {
        max-width: 440px;
        font-size: 17px;
      }


      @include media-breakpoint-up(xl) {
        max-width: 640px;
      }


      @media (min-width: 1400px) {
        max-width: 840px;
      }
    }
  }

  .benefits-block {
    background-color: $brand;
    text-align: center;
    margin: 0px auto;

    @include media-breakpoint-up(md) {
      margin: 100px auto 0;
    }


    h2.sub-title.inverted.slant {
      display: inline-block;
      margin-bottom: 50px;
      margin-top: 40px;
      background-color: white;
      padding: 10px 20px;
      transform: skewX(-30deg);
      font-family: $font-secondary;

      span {
        display: block;
        transform: skewX(30deg);
        font-size: 24px;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
        }
      }
    }

    .benefit {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0 10px;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }


      img {
        max-width: 180px;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      strong {
        font-size: 1.1em;
        text-transform: uppercase;
        text-align: center;

        @include media-breakpoint-up(sm) {
          text-align: left;
        }
      }
    }

    p,
    strong {
      color: white;
    }

    .extra-info {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .case-studies-block {
    margin-top: 100px;
    position: relative;

    h2 {
      text-align: center;
      color: white;
      padding-top: 30px;
      font-size: 2.3em;
    }
  }

  .case-study-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    align-items: flex-end;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }
  }

  .case-study {
    position: relative;
    width: 95%;
    margin-bottom: 100px;

    &:nth-child(2) {
      left: 15%;
    }

    &:first-child,
    &:last-child {
      left: -10%;
    }

    > div.skew {
      transform: skewX(-10deg);
      overflow: hidden;
      padding-top: 100%;
      position: relative;
    }

    .un-skew {
      transform: translateX(-50%) skewX(10deg);
      position: absolute;
      top: 0;
      width: 124%;
      left: 50%;
      height: 100%;
    }

    &:first-child {
      .case-study-info {
        right: 0;
        left: auto;
        transform: skewX(-10deg) translate(15%, 50%);

        @include media-breakpoint-up(md) {
          transform: skewX(-10deg) translate(38%, 40%);
        }
      }
    }

    @include media-breakpoint-up(sm) {
      width: 70%;

      &:nth-child(2) {
        left: 38%;
      }

      &:first-child,
      &:last-child {
        left: -7%;
      }
    }


    @include media-breakpoint-up(md) {
      width: 26%;
      margin-bottom: 0;

      &:first-child {
        left: -40px;
        margin-bottom: 40px;
      }

      &:nth-child(2) {
        left: auto;
      }

      &:last-child {
        left: 40px;
        margin-bottom: 40px;
      }
    }
  }

  .case-study-image {
    display: block;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 100% 0 0;

    p {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(75, 68, 61, 0.692);
      color: #fff;
      padding: 30px 20%;
      transform: translateX(-100%);
      transition: .3s ease-in-out;
      font-size: 1.3em;
    }

    &:hover p {
      transform: translateX(0%);
    }
  }

  .case-study-info {
    transform: skewX(-10deg) translateY(45%);
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 252px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;

    .un-skew {
      width: 100%;
      padding: 15px 10px;
      position: relative;
      top: 0;
      text-align: center;
    }

    .secondary {
      margin: 0;
      text-align: center;
      font-family: $font-secondary;
      line-height: 1.4;
      font-size: 1.2em;
      padding: 0 15px;
    }
  }

  section.case-studies-block:after {
    content: "";
    display: block;
    width: calc(78% + 44px);
    background-color: $brand;
    position: absolute;
    top: 0;
    transform: skewX(-10deg);
    z-index: -1;
    height: 22%;
    left: -5%;

    @include media-breakpoint-up(md) {
      height: 100%;
      left: -4%;
    }
  }

  .case-study:last-child .case-study-info {
    left: auto;
    right: -85px;
    transform: skewX(-10deg) translate(-27%, 45%);

    @include media-breakpoint-up(sm) {
      right: -151px;
    }


    @include media-breakpoint-up(md) {
      left: -0px;
      right: auto;
    }
  }

  a.case-study-info h4 {
    margin-bottom: 0;
  }

  a.case-study-info .large {
    font-size: 1.2em;
    color: #4a4a4a;
    margin-bottom: 4px;
  }

  a.case-study-info:nth-child(2) {
    left: -15px;

    @include media-breakpoint-up(sm) {
      left: -151px;
    }


    @include media-breakpoint-up(md) {
      left: auto;
      right: 5%;
    }


    @include media-breakpoint-up(lg) {
      right: 17%;
    }
  }

  .about-block {
    margin-top: 50px;

    @include media-breakpoint-up(md) {
      margin-top: 150px;
    }
  }

  section.about-block .container-fluid {
    padding: 0;
    max-width: 100%;
  }

  .about-block .skew-image {
    display: block;
    position: relative;
    overflow: hidden;
    left: 0;
    padding-top: 55%;
    margin-top: 40px;

    @include media-breakpoint-up(md) {
      left: 5.2%;
      transform: skewX(-20deg);
      padding-top: 30%;
      margin-top: 0px;
    }
  }

  .about-block .un-skew-image {
    position: absolute;
    height: 100%;
    top: 0;
    background-size: cover;
    background-position: center;
    width: 100%;
    left: 0;

    @include media-breakpoint-up(md) {
      width: 134%;
      transform: translateX(-50%) skewX(20deg);
      left: 50%;
    }
  }

  section.about-block .container-fluid .row {
    display: flex;
    align-items: center;
  }

  .about-info {
    padding: 0 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-info h2,
  .about-info p {
    width: 100%;
  }

  .about-info h2 {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  .about-info a {
    display: block;
    transform: skewX(-40deg);
    background-color: $brand;
    padding: 20px 70px;
    margin-top: 30px;
  }

  .about-info span {
    display: block;
    transform: skewX(40deg);
    color: white;
    font-size: 1.1em;
    text-transform: uppercase;
  }

  section.guide-block {
    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: 150px;
    }


    .container-fluid {
      max-width: 100%;
      padding-right: 0;
      padding-left: 0;

      .row {
        background-color: $brand;
        display: flex;
      }
    }

    .guide-info {
      padding-left: 5vw;
      padding-top: 50px;
      padding-bottom: 50px;

      h2 {
        color: white;
        text-align: center;
        margin-bottom: 50px;
        font-size: 1.6rem;

        @include media-breakpoint-up(sm) {
          font-size: 2.3rem;
        }
      }

      p {
        color: white;
        padding: 0 10px;
        font-size: 14px;
        line-height: 1.5rem;
        margin-bottom: 20px;

        @include media-breakpoint-up(sm) {
          padding: 0;
          font-size: 17px;
          line-height: 1.7;
          margin-bottom: 100px;
        }
      }

      .btn {
        background-color: white;
        color: $brand;
      }
    }

    .skew-image {
      position: relative;
      transform: skewX(-20deg) translateX(25%) scale(1.2);
      overflow: hidden;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }


      .un-skew-image {
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 168%;
        transform: translateX(-50%) skew(20deg);
      }
    }

    .guide-questions {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: hidden;

      @include media-breakpoint-up(sm) {
        margin-bottom: 50px;
      }
    }

    .step {
      min-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      will-change: transform;
      transform: translateX(0%);
      transition: 0.5s ease-in-out;
      opacity: 0;
      position: relative;
      z-index: 1;
      white-space: pre-line;

      &.active {
        opacity: 1;
        z-index: 2;
      }

      a {
        padding: 10px 30px;

        &:before {
          display: none;
        }
      }

      h3 {
        color: white;
        text-transform: uppercase;
        text-align: center;
      }

      @include media-breakpoint-up(lg) {
        h3 {
          text-align: left;
        }
      }
    }

    .options,
    .marketing_option {
      display: flex;
      flex-direction: column;

      label {
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          color: white;
          font-size: 23px;
          text-transform: uppercase;
        }

        &:hover {
          cursor: pointer;

          .box:after {
            opacity: 1;
          }
        }
      }

      .box {
        width: 30px;
        height: 30px;
        border: 3px solid white;
        margin-right: 15px;
        position: relative;

        &:after {
          position: relative;
          width: 80%;
          height: 80%;
          top: 50%;
          left: 50%;
          background: white;
          display: block;
          content: "";
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          will-change: opacity;
        }
      }

      input {
        display: none;

        &:checked + label .box:after {
          opacity: 1;
        }
      }
    }

    .marketing_option label {
      span {
        font-size: 13px;
      }

      .box {
        width: 20px;
        height: 20px;
      }
    }

    .submit {
      display: flex;
      border: 3px solid white;
      margin: 25px 0 14px;

      input {
        color: white;
        width: 100%;
        border: none;
        background-color: transparent;
        padding: 5px 10px;
        -webkit-appearance: none;

        &::placeholder {
          color: white;
        }
      }

      button {
        padding: 0 20px 0 10px;
        transform: skewX(0deg);
        background-color: #fcfdff9e;
        display: block;
        -webkit-appearance: none;
        border: none;
        outline: none;
        transition: 0.2s ease-in-out;

        &:before {
          display: none;
        }

        &:hover {
          background-color: darken($brand, 5%);
        }
      }
    }

    .email-open-container {
      margin-top: 20px;
    }

    .email-me-a-copy {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 95%;

      &.open {
        max-height: 500px;
      }

      .marketing_option {
        white-space: pre-line;
      }

      form {
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($brand, 0.83);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 21px;
          color: white;
          opacity: 0;
          pointer-events: none;
          z-index: 3;
          transition: opacity 0.3s ease-in-out;
        }

        &:before {
          content: "SENDING";
        }

        &.sending:before {
          opacity: 1;
          pointer-events: auto;
        }

        .guide-error-message {
          display: none;

          p {
            margin-bottom: 5px;
            color: white;
            background-color: #ff5454;
            padding: 5px 10px;
            font-size: 15px;
            border-radius: 4px;
          }

          &.has-error {
            display: block;
          }
        }
      }

      .success-message {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($brand, 0.83);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        z-index: 3;
        transition: opacity 0.3s ease-in-out;

        p {
          font-size: 21px;
          color: white;
          margin: 0;
        }

        &.sent {
          opacity: 1;
          pointer-events: auto;
        }
      }

      @include media-breakpoint-up(lg) {
        width: 100%;
        max-width: 600px;
      }
    }
  }

  section.seminar-block {
    margin-top: 0;
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
      margin-top: 150px;
      margin-bottom: 130px;
    }
  }

  section.seminar-block .container-fluid {
    padding: 0;
    max-width: 80%;

    @include media-breakpoint-up(md) {
      max-width: 100%;
    }
  }

  .seminar-block .skew-image {
    position: relative;
    overflow: hidden;
    padding-top: 60%;
    transform: scale(1.2);

    @include media-breakpoint-up(md) {
      padding-top: 32%;
      transform: skewX(-20deg) translateX(-20%) scale(1.2);
    }
  }

  .seminar-block .un-skew-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(md) {
      left: 50%;
      width: 137%;
      transform: skewX(20deg) translateX(-50%);
    }
  }

  section.seminar-block .container-fluid .row {
    display: flex;
    background-color: #f5f6fc;
  }

  .seminar-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 5vw;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 0;
    padding-left: 50px;

    .btn {
      transform: skewX(-20deg) translateY(50%);
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 100px;

      .btn {
        transform: skewX(-20deg);
      }
    }
  }

  .seminar-info h2,
  .about-block h2 {
    width: 100%;
    margin-bottom: 30px;
    font-size: 2rem;

    @include media-breakpoint-up(sm) {
      font-size: 2.4em;
    }
  }

  .seminar-info a {
    margin-top: 30px;
    background-color: $brand;
    padding: 20px 50px;
    color: white;
    text-transform: uppercase;
    transform: skewX(-20deg);
  }

  .seminar-info a > span {
    transform: skewX(20deg);
    display: block;
  }

  .seminar-info p,
  .about-block p {
    padding: 0 10px;
    font-size: 14px;
    line-height: 1.5rem;

    @include media-breakpoint-up(sm) {
      font-size: 17px;
      line-height: 1.7;
      padding: 0;
    }
  }
}

.video-block {
  margin: 50px auto 0;
  padding-left: 0;
  padding-right: 0;

  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

    .row {
      flex-direction: column-reverse;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
  }

  .video-container {
    width: 100%;

    video {
      width: 100%;
    }
  }

  .information {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin-bottom: 30px;
      font-size: 2rem;

      @include media-breakpoint-up(sm) {
        font-size: 2.7em;
      }
    }

    p {
      padding-right: 25px;
      font-size: 14px;
      line-height: 1.5;

      @include media-breakpoint-up(sm) {
        line-height: 1.7;
        font-size: 17px;
      }
    }
  }
}

.large-video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 30px;

    svg {
      fill: white;
      z-index: 10001;
      display: block;
    }
  }

  video {
    max-width: 100%;
    max-height: 80vh;
  }

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
}
